










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.addlist {
  h3 {
    display: flex;
    border-left: 3px solid rgb(92, 107, 232);
    span {
      margin-left: 20px;
    }
    margin-bottom: 30px;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div {
    margin-bottom: 5px;
  }
  > div:last-child {
    border: 0;
    margin-bottom: 0;
  }
}
.form-box {
  padding: 10px 20px;
  .form {
    width: 80%;
    padding: 20px;
  }
  .form-bg {
    background-color: rgb(247, 248, 253);
  }
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  width: 80%;
  button {
    padding: 12px 30px;
  }
}
.tip {
  position: relative;
  z-index: 100;
  // width: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px #eee;
}
.company {
  -webkit-input-placeholder {
    font-size: 12px;
  }
  -moz-placeholder {
    font-size: 12px;
  }
}
